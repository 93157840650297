export const homePageWithCux = [
  {
    id: 1,
    content:
      'Finally, understand the whole and the real digital experience of your clients',
  },
  {
    id: 2,
    content:
      'Identify users’ pain points & missed opportunities for conversion increase',
  },
  {
    id: 3,
    content: 'Implement user perspective across all digital activities',
  },
  {
    id: 4,
    content: 'Track user behavior across all your digital touchpoints',
  },
  {
    id: 5,
    content: 'Optimize user experiences to improve engagement and conversions',
  },
  {
    id: 6,
    content: 'Focus only on data that matters',
  },
];
