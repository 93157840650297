interface Props {
  data: {
    title: string;
    videoUrl: string;
  };
}

const HomePageVideo = (props: Props) => {
  const { title, videoUrl } = props.data;

  return (
    <div className='home-page-video'>
      <div className='home-page-video-container'>
        <p className='home-page-video__text'>{title}</p>
        <div className='home-page-video__video-container'>
          <iframe
            width='560'
            height='315'
            src={videoUrl}
            title='YouTube video player'
            frameBorder={'0'}
            allowFullScreen
            className='home-page-video__video'
          ></iframe>
        </div>
      </div>
    </div>
  );
};

export default HomePageVideo;
