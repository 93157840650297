export const homePageTimelineData = [
  {
    id: 1,
    title: `Data scattered across the organization`,
    content:
      "CUX is a way to <strong>democratize analytics across an enterprise. With a self-service analytics</strong>, you can make sure, that everybody – all teams and employees – can have immediate access not only to data but – what's more important – to valuable insights for business growth. This way provides <strong>frictionless access to data for any decision-maker on a daily basis</strong>.",
    img: '/hp_timeline_1.svg',
    width: 491,
    height: 352,
    alt: 'Data scattered across the organization',
  },
  {
    id: 2,
    title: `No time for Analytics`,
    content: `With CUX you can <strong>save up to 5x on analytics and drawing valuable conclusions</strong>. How? In a few simple, game-changing steps:
    <ul>
    <li><div>1</div> <p><strong>Goal-oriented Analytics</strong>. CUX automatically selects for you ONLY the data that is relevant to your individual business goal. Focus only on what's really important.</p></li>
    <li><div>2</div> <p><strong>Development-free Analytics</strong>. With the CUX snippet implemented, you’ll codelessly set up any customer’s journey in no more than just 10 minutes, and you’ll see your users’ experience along the way.</p></li>
    <li><div>3</div> <p><strong>AIocado</strong>. Our very own AI-concierge will show you precisely the reasons for your conversion drops, places of users' frustration, and more. Just ask him a question & find immediate answers!</p></li>
    </ul>
    `,
    img: '/hp_timeline_2.svg',
    width: 345,
    height: 343,
    alt: 'No time for Analytics',
  },
  {
    id: 3,
    title: `Data Overload`,
    content: `Deciding on what's really important regarding the overflow of data can be challenging. Some metrics would be crucial for your business developers, some others for the marketing team. With CUX you get access to unlimited sets of data pre-selected for specific, different end goals. 
    <strong>All teams across your organization can thrive on behavioral data in no time</strong>. 
    </br>
    </br>
    <p>Thanks to the <strong>AIocado</strong> (our AI-driven Digital Data Concierge) and Goal-Oriented Analytics, you can say an adieu to constant DATA SPAM.</p>`,
    img: '/hp_timeline_3.svg',
    width: 219,
    height: 462,
    alt: 'Data Overload',
  },
];
