import Image from 'next/image';
import { useEffect, useState } from 'react';
import BasicButton from '../BasicButton/BasicButton';
import OutlineButton from '../OutlineButton/OutlineButton';

const AvocadoBreakSection = () => {
  const [ref, setRef] = useState<string | null>('');

  useEffect(() => {
    if (localStorage.getItem('ref')) {
      setRef(localStorage.getItem('ref'));
    }
  }, []);

  return (
    <div className='avocado-break-section'>
      <div className='avocado-break-section-container'>
        <div className='avocado-break-section__avocado-break-box avocado-break-box'>
          <p className='avocado-break-box__title'>{`Let CUX be the guacamole to your website's chips`}</p>
          <p className='avocado-break-box__subtitle'>
            And sign up for a free trial today!
          </p>
          <div className='avocado-break-box__buttons'>
            <BasicButton
              id='start_trial_stories_slider'
              url={`https://app.cux.io/register?ref=${ref}`}
              content='start free trial'
            />
            <OutlineButton
              id='start_trial_stories_slider'
              url={`/book-meeting/`}
              content='meet with expert'
            />
          </div>
        </div>
        <div className='avocado-break-section__avocado-break-image avocado-break-image'>
          <Image
            src={'/avocado_break_section.svg'}
            width={213}
            height={240}
            alt='avocado image'
            objectFit='cover'
            className='avocado-break-image__avocado-image'
          />
        </div>
      </div>
    </div>
  );
};

export default AvocadoBreakSection;
