import { Autoplay, Navigation, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar } from '@fortawesome/pro-solid-svg-icons';
import { faAngleLeft, faAngleRight } from '@fortawesome/pro-light-svg-icons';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import Image from 'next/image';
import Link from 'next/link';
import BasicButton from '../BasicButton/BasicButton';
import { useEffect, useState } from 'react';
import OutlineButton from '../OutlineButton/OutlineButton';
import { useRouter } from 'next/router';

interface Props {
  data: {
    title: string;
    stories: ReviewDataTypes[];
  };
}

interface ReviewDataTypes {
  id?: number;
  story: string;
}

const HomePageStories = (data: Props) => {
  const { title, stories } = data?.data;
  const [ref, setRef] = useState<string | null>('');

  const router = useRouter();

  useEffect(() => {
    if (router.query.ref) {
      localStorage.setItem('ref', router.query.ref as string);
    }

    if (localStorage.getItem('ref')) {
      setRef(localStorage.getItem('ref'));
    }
  }, [router.query.ref]);

  const registerUrl = ref ? `register?ref=${ref}` : 'register';

  return (
    <div className='home-page-stories-slider'>
      <div className='home-page-stories-slider-wrapper'>
        <div className='home-page-stories-slider-main-container'>
          <header className='home-page-stories-slider__header'>
            <p className='home-page-stories-slider__header-text'>{title}</p>
          </header>

          <div className='home-page-stories-slider-container'>
            <Swiper
              modules={[Pagination, Navigation, Autoplay]}
              slidesPerView='auto'
              spaceBetween={50}
              pagination={{ clickable: true }}
              loop={true}
              loopFillGroupWithBlank={true}
              autoplay={{ delay: 5000 }}
              navigation={{
                nextEl: '.home-page-stories-slider__arrows--next',
                prevEl: '.home-page-stories-slider__arrows--prev',
              }}
              breakpoints={{
                0: {
                  slidesPerView: 1,
                  spaceBetween: 50,
                },
                1024: {
                  slidesPerView: 3,
                  spaceBetween: 50,
                },
                1700: {
                  slidesPerView: 3,
                  spaceBetween: 100,
                },
              }}
            >
              {stories.map((item: ReviewDataTypes) => {
                return (
                  <SwiperSlide key={item.id}>
                    <div className='home-page-stories-slider__home-page-stories-slider-item home-page-stories-slider-item'>
                      <div className='home-page-stories-slider-item__home-page-stories-slider-content home-page-stories-slider-content'>
                        <div className='home-page-stories-slider-content__home-page-stories-slider-review home-page-stories-slider-review'>
                          {' '}
                          <p className='home-page-stories-slider-review__home-page-stories-slider-content'>
                            {item.story}
                          </p>
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                );
              })}
              <div className='home-page-stories-slider__arrows'>
                <button
                  id='stories_left_slide'
                  aria-label='Previous slide'
                  className='home-page-stories-slider__arrows--prev'
                >
                  {' '}
                  <FontAwesomeIcon
                    icon={faAngleLeft}
                    className='home-page-stories-slider__arrows-icon'
                  />
                </button>
                <button
                  id='stories_right_slide'
                  aria-label='Next slide'
                  className='home-page-stories-slider__arrows--next'
                >
                  {' '}
                  <FontAwesomeIcon
                    icon={faAngleRight}
                    className='home-page-stories-slider__arrows-icon'
                  />
                </button>
              </div>
            </Swiper>
          </div>

          <div className='home-page-stories-slider__stories-case-studies stories-case-studies'>
            <Image
              src={'/avocado-case-studies.svg'}
              width={275}
              height={399}
              alt='avocado case studies'
              className='stories-case-studies__avocado-image'
            />
            <div className='stories-case-studies__hp-case-studies-box hp-case-studies-box'>
              <p className='hp-case-studies-box__text'>
                Check our <span>CUX-TASTIC</span> Client Stories
              </p>
              <Link href='/case-studies'>
                <button className='hp-case-studies-box__button'>
                  Check our case studies
                </button>
              </Link>
            </div>

            <div className='hp-case-studies-box__left-arm'>
              <Image
                src={'/left-arm-case-studies.svg'}
                width={28}
                height={63}
                alt='avocado arm'
              />
            </div>
            <div className='hp-case-studies-box__right-arm'>
              <Image
                src={'/right-arm-case-studies.svg'}
                width={28}
                height={63}
                alt='avocado arm'
              />
            </div>
          </div>

          <div className='home-page-stories-slider__stories-trial stories-trial'>
            <p className='stories-trial__text'>
              Start your <strong>FREE Trial</strong> today and become part of
              <br /> our many customer successes
            </p>
            <div className='stories-trial__buttons'>
              <BasicButton
                id='start_trial_stories_slider'
                url={`https://app.cux.io/${registerUrl}`}
                content='start free trial'
              />
              <OutlineButton
                id='start_trial_stories_slider'
                url={`/book-meeting/`}
                content='meet with expert'
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomePageStories;
