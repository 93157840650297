import Image from 'next/image';

const FoundersSection = () => {
  return (
    <section className='founders-section'>
      <div className='founders-section__founders-text-content founders-text-content'>
        <header className='founders-text-content__header'>
          <p className='founders-text-content__subtitle'>
            A word from the founders
          </p>
          <p className='founders-text-content__title'>
            CUX: From necessity to
            <br /> Digital Experience disruption
          </p>
        </header>
        <div className='founders-text-content__text'>
          <p className='founders-text-content__text-paragraph'>
            {`CUX is a tool that was created out of a need and out of a mixture of
            experiences. The need to understand WHAT and WHY is happening inside
            the products, what problems users face, which frustrations are a
            minor breeze, and which can tear your safe business' roof off. And
            from the experience of Paulina – a behavioral researcher with a
            background in marketing – and Kamil – a developer, with the urge to
            always look deeper.`}
          </p>

          <p className='founders-text-content__text-paragraph'>
            CUX is also an answer to a more universal concern: building a
            successful business in a world where we are constantly finding
            ourselves with a shortage of time and competence.
          </p>

          <p className='founders-text-content__text-paragraph'>
            No one understands the necessity to maximize efficiency better than
            a married couple of founders who not only shoulder the development
            of a startup in a very disrupted world of behavioral analytics, but
            also raise two toddlers. And, on top of that, are digital nomads!
          </p>

          <p className='founders-text-content__text-paragraph'>Hence, CUX.</p>
          <p className='founders-text-content__text-paragraph'>
            Hope you’ll find it as useful as we do every day.
          </p>

          <p className='founders-text-content__text-paragraph--special'>
            Paulina & Kamil Walkowiak
          </p>
        </div>
      </div>
      <div className='founders-section__founders-photo-content founders-photo-content'>
        <Image
          src='/founders_photo.png'
          alt='hero image'
          width={569}
          height={410}
          objectFit='cover'
          className='founders-photo-content__image'
        />
      </div>
    </section>
  );
};

export default FoundersSection;
