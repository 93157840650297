import {
  faDatabase,
  faLock,
  faShieldCheck,
  faSquareCode,
} from '@fortawesome/pro-light-svg-icons';

export const homePageFeaturesData = [
  {
    id: 1,
    title: 'Goal-Oriented Analytics',
    subtitle: 'UNLOCK THE POWER OF YOUR DATA',
    content:
      "With CUX, you can unlock the power of your data by setting goals that align with your business objectives. Our platform provides deep insights into user behavior, allowing you to identify opportunities for growth and improvement. Whether you're looking to increase conversions, reduce churn, or optimize your marketing campaigns, CUX pre-selects data that matters to you. <br/><br/>Say “YES” to more time and “NO” to constant data SPAM!",
    img: '/hp_feature_1_480.webm',
    mp4: '/hp_feature_1_480.mp4',
    width: 585,
    height: 410,
    alt: 'Goal-Oriented Analytics',
    summary: false,
  },
  {
    id: 2,
    title: 'Development-Free Analytics',
    subtitle: 'SAVE TIME & RESOURCES',
    content:
      "With CUX, you don't need developers to get started with analytics. Our platform is designed to be easy to use, with a development-free approach that allows you to get up and running quickly. Whether you're a small business or a large enterprise, our platform provides a powerful analytics solution that's accessible to everyone – no matter how advanced you are!",
    img: '/hp_feature_2_480.webm',
    mp4: '/hp_feature_2_480.mp4',
    width: 585,
    height: 410,
    alt: 'Development-Free Analytics',
    summary: false,
  },
  {
    id: 3,
    title: 'Retroactive Analytics',
    subtitle: 'LOOK BACK & LEARN',
    content:
      'Our platform provides retroactive analytics that allow you to look back and learn from your past data. With retroactive analytics, you can identify trends and patterns that may have been missed in real-time, gaining valuable insights that can help you improve your business.',
    img: '/hp_feature_3_480.webm',
    mp4: '/hp_feature_3_480.mp4',
    width: 585,
    height: 410,
    alt: 'Retroactive Analytics',
    summary: false,
  },
  {
    id: 4,
    title: 'Privacy by Design',
    subtitle: 'PROTECT YOUR DATA & YOUR BUSINESS',
    content:
      'We take security seriously. Our platform is built with the highest security standards in mind, ensuring that your data is protected at all times. We use advanced encryption and data protection technologies to safeguard your data, giving you peace of mind and confidence in our platform.',
    img: '/hp_feature_4_480.webm',
    mp4: '/hp_feature_4_480.mp4',
    width: 585,
    height: 410,
    alt: 'Privacy by Design',
    summary: false,
    privacyFeatures: [
      { icon: faLock, text: 'GDPR-compliant' },
      { icon: faShieldCheck, text: 'SSL secured' },
      { icon: faDatabase, text: 'EEA Data stored' },
      { icon: faSquareCode, text: 'Code audit on demand' },
    ],
  },
  {
    id: 5,
    title: 'Human2Human Approach',
    subtitle: 'PERSONALIZED SUPPORT & SERVICE',
    content:
      'We believe in delivering analytics with a Human2Human approach. We provide personalized support and service to every client, ensuring that you have the assistance you need to get the most out of our platform. Whether you need help getting started, or have a complex analytics question, our team of experts is here to help you succeed.',
    img: '/hp_feature_5_480.webm',
    mp4: '/hp_feature_5_480.mp4',
    width: 585,
    height: 410,
    alt: 'Human2Human Approach',
    summary: false,
    additionalButton: {
      text: 'Ask for add-on analytical support',
      url: `/book-meeting/`,
    },
  },
  {
    id: 6,
    title: '',
    subtitle: '',
    content: `We know that analytics should be <strong>goal-oriented, development-free, retroactive, and secure</strong>. We also feel that it should be delivered with a <strong>Human2Human approach</strong>. That's why we've developed a powerful platform that's designed specifically for enterprise clients, delivering the insights you need to make <strong>data-driven decisions that drive growth and success</strong>.`,
    img: '/hp_feature_summary.png',
    width: 1473,
    height: 1471,
    alt: 'Summary',
    summary: true,
  },
];
