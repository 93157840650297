import Image from 'next/image';
import { useEffect, useRef, useState } from 'react';
import BasicButton from '../BasicButton/BasicButton';
import OutlineButton from '../OutlineButton/OutlineButton';
import { homePageTimelineData } from './HomePageTimelineData';
import parse from 'html-react-parser';

const HomePageTimeline = () => {
  const [ref, setRef] = useState<string | null>('');
  const [contentHeight, setContentHeight] = useState(0);
  const [completion, setCompletion] = useState(0);

  const contentRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (localStorage.getItem('ref')) {
      setRef(localStorage.getItem('ref'));
    }
  }, []);

  useEffect(() => {
    const updateScrollCompletion = () => {
      setContentHeight(Number(contentRef?.current?.clientHeight));

      const scrollHeight = contentHeight - 200;
      const currentProgress = window.scrollY - contentHeight;

      if (scrollHeight && scrollHeight !== currentProgress) {
        setCompletion(
          Number(((currentProgress + 100) / contentHeight).toFixed(2)) * 100
        );
      }

      window.addEventListener('scroll', updateScrollCompletion);

      return () => {
        window.removeEventListener('scroll', updateScrollCompletion);
      };
    };

    window.addEventListener('scroll', updateScrollCompletion);
  }, [completion]);

  return (
    <div className='home-page-timeline'>
      <header className='home-page-timeline__header'>
        <p className='home-page-timeline__header-text'>
          Smash your analytical
          <br /> problems with CUX:
        </p>
      </header>
      <div className='home-page-timeline-container'>
        <div ref={contentRef} className='home-page-timeline__timeline timeline'>
          {homePageTimelineData.map((item, index) => {
            return (
              <div
                key={index}
                className='timeline__timeline-item timeline-item'
              >
                <div className='timeline-item__timeline-item-text timeline-item-text'>
                  <p className='timeline-item-text__title'>{`0${index + 1}`}</p>
                  <p className='timeline-item-text__title'>{item.title}</p>
                  <div className='timeline-item-text__content'>
                    {parse(item.content)}
                  </div>
                </div>
                <div className='timeline-item__timeline-item-image timeline-item-image'>
                  <div className='timeline-item-image__image-container'>
                    <Image
                      src={item.img}
                      width={item.width}
                      height={item.height}
                      alt={item.alt}
                      objectFit='cover'
                      className='timeline-item-image__image'
                    />
                  </div>
                </div>
              </div>
            );
          })}
          <div className='home-page-timeline__scroll-indicator-track'></div>
          <div
            className='home-page-timeline__scroll-indicator'
            style={
              (completion > 100
                ? { transform: `translateY(0%)` }
                : { transform: `translateY(${completion - 100}%)` }) ||
              (completion < 0
                ? { transform: `translateY(0%)` }
                : { transform: `translateY(${completion + 100}%)` })
            }
          ></div>
        </div>
      </div>
    </div>
  );
};

export default HomePageTimeline;
