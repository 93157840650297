const noAvatarGraphic =
  'https://content.cux.io/uploads/no_av_user_70cb0fa13d.png';

export const homePageReviews = [
  {
    id: 1,
    img: 'https://images.g2crowd.com/uploads/avatar/image/1660104/thumb_square_e96e26d860ee8af149de6a8a25d1822e.jpeg',
    author: 'Paweł G',
    position: 'Team Manager',
    stars: 5,
    textContent:
      'One of the key features of this tool is that it is able to highlight where the user pain points are within your site and give you tips on how to improve them, which makes it a great investment.',
    url: 'https://www.g2.com/products/cux-io/reviews/cux-io-review-6668356',
  },
  {
    id: 2,
    img: noAvatarGraphic,
    author: 'Anna S',
    position: 'Online Marketing Manager',
    stars: 5,
    textContent:
      'We are making more user-friendly campaigns and landing pages. We can easily spot bottlenecks in our conversion funnels. Our team is less occupied with constant analysis.',
    url: 'https://www.g2.com/products/cux-io/reviews/cux-io-review-5383302',
  },
  {
    id: 3,
    img: 'https://images.g2crowd.com/uploads/avatar/image/562081/thumb_square_6a0e3853122b0ceef8d28cef5ffce6b5.jpeg',
    author: 'Alicja',
    position: 'Content & Digital Marketing Specialist',
    stars: 4,
    textContent:
      "You can truly see the visitor's behaviour, create segments, groups of similar activities, spot patterns - essential knowledge in the world of digital marketing to improve your business results.",
    url: 'https://www.g2.com/products/cux-io/reviews/cux-io-review-5155176',
  },
  {
    id: 4,
    img: 'https://images.g2crowd.com/uploads/avatar/image/327631/thumb_square_b656549a48bc6c12895c244cfce85f34.jpeg',
    author: 'Martyna T',
    position: 'Service Design Lead & Digital Marketing Trainer',
    stars: 5,
    textContent:
      'It’s amazing that I can analyze users’ feelings – something that is not physical, and I can measure it with numbers.',
    url: 'https://www.g2.com/products/cux-io/reviews/cux-io-review-4319253',
  },
  {
    id: 5,
    img: noAvatarGraphic,
    author: 'Brian M',
    position: 'Marketing',
    stars: 5,
    textContent:
      "I like the fact that I can see what's going on on my site and how people are engaging with the web pages. It helps me to understand their actions and to make better decisions on improvements.",
    url: 'https://www.g2.com/products/cux-io/reviews/cux-io-review-4356686',
  },
];

export const homePageMedals = [
  {
    id: 1,
    medal: '/hp_medal_1.svg',
    alt: 'G2 medal',
    width: 82,
    height: 106,
    url: '',
  },

  {
    id: 2,
    medal: '/hp_medal_2.svg',
    alt: 'G2 medal',
    width: 81,
    height: 90,
    url: '',
  },

  {
    id: 3,
    medal: '/hp_medal_3.svg',
    alt: 'G2 medal',
    width: 82,
    height: 106,
    url: '',
  },

  {
    id: 4,
    medal: '/hp_medal_4.svg',
    alt: 'G2 medal',
    width: 82,
    height: 106,
    url: '',
  },

  {
    id: 5,
    medal: '/hp_medal_5.svg',
    alt: 'G2 medal',
    width: 185,
    height: 89,
    url: '',
  },

  {
    id: 6,
    medal: '/hp_medal_6.svg',
    alt: 'G2 medal',
    width: 198,
    height: 73,
    url: '',
  },

  {
    id: 7,
    medal: '/hp_medal_7.svg',
    alt: 'G2 medal',
    width: 76,
    height: 59,
    url: '/303-innovation-fund/',
  },
];
