export const homePageStories = [
  {
    id: 1,
    story:
      'We helped our client save 50k € in 15 minutes thanks to goal-oriented analysis.',
  },

  {
    id: 2,
    story: 'Our client has reduced frustration on the contact page by 70%!',
  },

  {
    id: 3,
    story: 'E-commerce increased conversion on mobile by 46% in 3 months!',
  },

  {
    id: 4,
    story:
      'Our client increased the conversion by 75% in 3 months, thus saving over 125k €.',
  },

  {
    id: 5,
    story:
      'Thanks to the user behavior analysis, our client was able to reduce support costs by 37%!',
  },

  {
    id: 6,
    story:
      'E-commerce improved customer journeys that generated almost 60k EUR/month losses in just few hours!',
  },
];
