import { faCircleCheck } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { homePageWithCux } from './HomePageWithCuxData';

const HomePageWithCux = () => {
  return (
    <div className='home-page-with-cux'>
      <div className='home-page-with-cux-container'>
        <div className='home-page-with-cux__home-page-with-cux-text-content home-page-with-cux-text-content'>
          <p className='home-page-with-cux-text-content__subtitle'>
            Why Goal-Oriented Analytics is a game-changer?
          </p>
          <p className='home-page-with-cux-text-content__title'>
            With CUX, you can:
          </p>

          <ul className='home-page-with-cux-text-content__list'>
            {homePageWithCux.map((item, index) => {
              return (
                <li
                  key={index}
                  className='home-page-with-cux-text-content__list-item'
                >
                  <FontAwesomeIcon
                    icon={faCircleCheck}
                    className='home-page-with-cux-text-content__list-item-icon'
                  />{' '}
                  <p className='home-page-with-cux-text-content__list-item-text'>
                    {item.content}
                  </p>
                </li>
              );
            })}
          </ul>
        </div>
        <div className='home-page-with-cux__home-page-with-cux-content'>
          <div className='home-page-with-cux__home-page-with-cux-content-container'>
            <iframe
              width='560'
              height='315'
              src='https://www.youtube.com/embed/TkOPT698Vzw'
              title='YouTube video player'
              frameBorder='0'
              allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
              allowFullScreen
              className='home-page-with-cux__home-page-with-cux-content-video'
            ></iframe>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomePageWithCux;
