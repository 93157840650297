import Link from 'next/link';

interface Props {
  id: string;
  url: string;
  content: string;
}

const OutlineButton = (props: Props) => {
  const { id, url, content } = props;

  return (
    <div className='outline-button-container'>
      <button className='outline-button' id={id}>
        <Link href={url}>
          <a className='outline-button__link'>{content}</a>
        </Link>
      </button>
    </div>
  );
};

export default OutlineButton;
