import Image from 'next/image';
import { homePageFeaturesData } from './HomePageFeaturesData';
import parse from 'html-react-parser';
import BasicButton from '../BasicButton/BasicButton';
import { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const HomePageFeatures = () => {
  const [ref, setRef] = useState<string | null>('');

  useEffect(() => {
    if (localStorage.getItem('ref')) {
      setRef(localStorage.getItem('ref'));
    }
  }, []);

  return (
    <div className='home-page-features'>
      <div className='home-page-features-container'>
        {homePageFeaturesData.map((item, index) => {
          return (
            <div
              key={index}
              className='home-page-features__home-page-features-item home-page-features-item'
            >
              <div className='home-page-features-item__home-page-features-item-text home-page-features-item-text'>
                {item.subtitle && (
                  <p className='home-page-features-item-text__subtitle'>
                    {item.subtitle}
                  </p>
                )}
                {item.title && (
                  <p className='home-page-features-item-text__title'>
                    {item.title}
                  </p>
                )}
                <p className='home-page-features-item-text__content'>
                  {parse(item.content)}
                </p>
                {item.additionalButton && (
                  <div className='home-page-features-item-text__content-button'>
                    <BasicButton
                      id='start_trial_home_page_features'
                      url={item.additionalButton.url}
                      content={item.additionalButton.text}
                    />
                  </div>
                )}
                {item.privacyFeatures && (
                  <div className='home-page-features-item-text__privacy-features-container'>
                    {item.privacyFeatures.map((item, index) => {
                      return (
                        <div
                          key={index}
                          className='home-page-features-item-text__privacy-features'
                        >
                          <FontAwesomeIcon
                            icon={item.icon}
                            className='home-page-features-item-text__privacy-features-icon'
                          />
                          <p className='home-page-features-item-text__privacy-features-text'>
                            {item.text}
                          </p>
                        </div>
                      );
                    })}
                  </div>
                )}
              </div>

              <div className='home-page-features-item__home-page-features-item-image home-page-features-item-image'>
                <div className='home-page-features-item-image__container'>
                  {item.summary ? (
                    <Image
                      src={item.img}
                      width={item.width}
                      height={item.height}
                      alt={item.alt}
                    />
                  ) : (
                    <video
                      width={item.width}
                      height={item.height}
                      preload='none'
                      autoPlay
                      loop
                      muted
                      playsInline
                      className='home-page-features-item-image__video'
                    >
                      <source
                        src={`${item.img}`}
                        type='video/webm'
                        className='home-page-features-item-image__video-source'
                      />
                      <source
                        src={`${item.mp4}`}
                        type='video/mp4'
                        className='home-page-features-item-image__video-source'
                      />
                    </video>
                  )}
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default HomePageFeatures;
