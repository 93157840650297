import { Autoplay, Navigation, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar } from '@fortawesome/pro-solid-svg-icons';
import { faAngleLeft, faAngleRight } from '@fortawesome/pro-light-svg-icons';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import Image from 'next/image';
import { homePageMedals } from './HomePageReviewsData';
import Link from 'next/link';

interface Props {
  data: {
    title: string;
    subtitle: string;
    reviews: ReviewDataTypes[];
  };
}

interface ReviewDataTypes {
  id?: number;
  img?: string;
  author?: string;
  position?: string;
  stars?: number;
  textContent?: string;
  url?: string;
}

const HomePageReviews = (data: Props) => {
  const { title, subtitle, reviews } = data?.data;

  return (
    <div className='home-page-slider'>
      <div className='home-page-slider-wrapper'>
        <div className='home-page-slider-main-container'>
          <header className='home-page-slider__header'>
            <p className='home-page-slider__header-text'>{title}</p>
            <p className='home-page-slider__header-subtitle-text'>{subtitle}</p>
          </header>

          <div className='home-page-slider-container'>
            <Swiper
              modules={[Pagination, Navigation, Autoplay]}
              slidesPerView='auto'
              spaceBetween={50}
              pagination={{ clickable: true }}
              loop={true}
              loopFillGroupWithBlank={true}
              autoplay={{ delay: 5000 }}
              navigation={{
                nextEl: '.home-page-slider__arrows--next',
                prevEl: '.home-page-slider__arrows--prev',
              }}
              breakpoints={{
                0: {
                  slidesPerView: 1,
                  spaceBetween: 50,
                },
                1024: {
                  slidesPerView: 3,
                  spaceBetween: 50,
                },
                1700: {
                  slidesPerView: 3,
                  spaceBetween: 100,
                },
              }}
            >
              {reviews.map((item: ReviewDataTypes) => {
                return (
                  <SwiperSlide key={item.id}>
                    <div className='home-page-slider__home-page-slider-item home-page-slider-item'>
                      <div className='home-page-slider-item__home-page-slider-content home-page-slider-content'>
                        <div className='home-page-slider-content__home-page-slider-author home-page-slider-author'>
                          <picture className='home-page-slider-author__picture'>
                            <Image
                              src={`${item.img}`}
                              width={48}
                              height={48}
                              alt='reviewer photo'
                              className='home-page-slider-author__image'
                            />
                          </picture>
                          <div className='home-page-slider-author__text-container'>
                            <p className='home-page-slider-author__author-text'>
                              {item.author}
                            </p>
                            <p className='home-page-slider-author__position-text'>
                              {item.position}
                            </p>
                            <div className='home-page-slider-author__stars-container'>
                              {[...Array(item.stars)].map((star, index) => (
                                <FontAwesomeIcon
                                  key={index}
                                  icon={faStar}
                                  className='home-page-slider-author__stars'
                                />
                              ))}
                            </div>
                          </div>
                        </div>
                        <div className='home-page-slider-content__home-page-slider-review home-page-slider-review'>
                          {' '}
                          <p className='home-page-slider-review__home-page-slider-content'>
                            {'„'}
                            {item.textContent}
                            {'”'}
                          </p>
                        </div>
                        {!item.url ? (
                          <></>
                        ) : (
                          <div className='home-page-slider-content__home-page-slider-g2 home-page-slider-g2'>
                            <Image
                              src='/g2-logo.png'
                              width={27}
                              height={27}
                              alt='g2 logo'
                              className='home-page-slider-g2__logo'
                            />
                            <div className='home-page-slider-g2__text-content'>
                              <p className='home-page-slider-g2__posted-text'>
                                Posted on
                              </p>
                              <a
                                href={item.url}
                                target='_blank'
                                rel='noopener noreferrer'
                                className='home-page-slider-g2__link'
                              >
                                G2.com
                              </a>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </SwiperSlide>
                );
              })}
              <div className='home-page-slider__arrows'>
                <button
                  id='reviews_left_slide'
                  aria-label='Previous slide'
                  className='home-page-slider__arrows--prev'
                >
                  {' '}
                  <FontAwesomeIcon
                    icon={faAngleLeft}
                    className='home-page-slider__arrows-icon'
                  />
                </button>
                <button
                  id='reviews_right_slide'
                  aria-label='Next slide'
                  className='home-page-slider__arrows--next'
                >
                  {' '}
                  <FontAwesomeIcon
                    icon={faAngleRight}
                    className='home-page-slider__arrows-icon'
                  />
                </button>
              </div>
            </Swiper>
          </div>
        </div>
      </div>
      <div className='home-page-slider__medals-box medals-box'>
        <div className='medals-box__medals-container'>
          {homePageMedals.map((item, index) => {
            return (
              <div key={index} className='medals-container__medals-item'>
                {item.url ? (
                  <Link
                    href={item.url}
                    className='medals-item__medals-image-link'
                  >
                    <a>
                      <Image
                        src={item.medal}
                        width={item.width}
                        height={item.height}
                        alt={item.alt}
                        className='medals-item__medals-image'
                        objectFit='cover'
                      />
                    </a>
                  </Link>
                ) : (
                  <Image
                    src={item.medal}
                    width={item.width}
                    height={item.height}
                    alt={item.alt}
                    className='medals-item__medals-image'
                    objectFit='cover'
                  />
                )}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default HomePageReviews;
