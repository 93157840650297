import { faArrowDownLong, faXmark } from '@fortawesome/pro-light-svg-icons';
import { faPlay } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Image from 'next/image';
import { useEffect, useState } from 'react';
import { useInView } from 'react-intersection-observer';

const SelfGuidedWalkthrough = () => {
  const [isOpen, setOpen] = useState(false);
  const [, setIsVisible] = useState(false);

  const { ref, inView } = useInView({
    threshold: 0.65,
  });

  useEffect(() => {
    if (inView) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  }, [inView]);

  return (
    <div className='walkthrough' ref={ref} id='self-guided-tour'>
      <div className='walktrough-container'>
        <div className='walkthrough__walkthrough-text-content walkthrough-text-content'>
          <p className='walkthrough-text-content__title'>
            CUX is the perfect
            <br /> complement to your website
          </p>
          <p className='walkthrough-text-content__subtitle'>{`It’s Avocado for your digital toast. We're like a digital detective, peeling back the layers to help you find ways to improve the digital user experience with data-driven decisions.`}</p>

          <p
            onClick={() => setOpen(true)}
            className='walkthrough-text-content__arrow-text'
          >
            Take a Self-Guided Tour
            <FontAwesomeIcon
              icon={faArrowDownLong}
              className='walkthrough-text-content__icon'
            />
          </p>
        </div>
        <div className='walkthrough__walkthrough-content'>
          <div className='walkthrough__walkthrough-content-container'>
            <div className='sl-embed'>
              <iframe
                className='sl-demo'
                src='https://app.storylane.io/demo/mmqdxjxcib1f'
                name='sl-embed'
                allow='fullscreen; camera; microphone'
                frameBorder='0'
                scrolling='no'
                title='CUX walkthrough'
              ></iframe>
            </div>
          </div>
        </div>
        <div className='walkthrough__walkthrough-thumbnail-content'>
          <div className='walkthrough__walkthrough-thumbnail-content-container'>
            <Image
              src={'/walkthrough.png'}
              width={575}
              height={436}
              alt='walkthrough thumbnail'
              className='walkthrough__walkthrough-thumbnail-image'
              onClick={() => setOpen(true)}
            />
            <div
              className='walkthrough__walkthrough-thumbnail-play-box walkthrough-thumbnail-play-box'
              onClick={() => setOpen(true)}
            >
              <p className='walkthrough-thumbnail-play-box__text'>
                Take a Self-Guided Tour
              </p>
              <div className='walkthrough-thumbnail-play-box__play-icon'>
                <FontAwesomeIcon
                  icon={faPlay}
                  className='walkthrough-thumbnail-play-box__icon'
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      {isOpen && (
        <div className='walkthrough__walkthrough-modal walkthrough-modal'>
          <div className='walkthrough-modal__content'>
            <div className='walkthrough-modal__walkthrough-modal-iframe walkthrough-modal-iframe'>
              <div className='walkthrough-modal-iframe__nav'>
                <FontAwesomeIcon
                  icon={faXmark}
                  className='walkthrough-modal-iframe__icon'
                  onClick={() => setOpen(false)}
                />
              </div>
              <div className='sl-embed'>
                <iframe
                  className='sl-demo'
                  src='https://app.storylane.io/demo/mmqdxjxcib1f'
                  name='sl-embed'
                  allow='fullscreen; camera; microphone'
                  frameBorder='0'
                  scrolling='no'
                  title='CUX walkthrough'
                ></iframe>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default SelfGuidedWalkthrough;
