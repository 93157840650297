import Link from 'next/link';

interface Props {
  id: string;
  content: string;
  url?: string;
  type?: 'submit' | 'reset' | 'button' | undefined;
  disabled?: boolean;
}

const BasicButton = (props: Props) => {
  const { id, url, content, type, disabled } = props;

  return (
    <div className='basic-button-container'>
      <button className='basic-button' id={id} type={type} disabled={disabled}>
        {type !== 'submit' && url && <Link href={url}>
          <a className='basic-button__link'>{content}</a>
        </Link>}
        {type === 'submit' && <span className='basic-button__link'>{content}</span>}
      </button>
    </div>
  );
};

export default BasicButton;
