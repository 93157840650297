import { useEffect, useState } from 'react';
import Image from 'next/image';
import BasicButton from '../BasicButton/BasicButton';
import TextTransition, { presets } from 'react-text-transition';

const TEXTS = ['E-commerce', 'Insurance', 'FinTech', 'You'];

const Hero = () => {
  const [index, setIndex] = useState(0);

  useEffect(() => {
    const intervalId = setInterval(() => setIndex((index) => index + 1), 3000);
    return () => clearTimeout(intervalId);
  }, []);

  return (
    <div className='hero'>
      <div className='hero-container'>
        <div className='hero__text-box-wrapper'>
          <div className='hero__main-text'>
            <h1 className='hero__title'>
              The Digital Experience <br />
              Analytics Tool for
              <div className='hero__title--transition-container'>
                <TextTransition springConfig={presets.wobbly}>
                  {TEXTS[index % TEXTS.length]}
                </TextTransition>
              </div>
            </h1>
            <p className='hero__subtitle'>
              It’s not about data. It’s about users’ real problems.
            </p>
          </div>
          {/* MEET WITH EXPERT BUTTON */}
          <div className='hero__meet_with_expert-container'>
            <BasicButton 
              id='meet_with_expert_button'
              url='/book-meeting/'
              content='meet with expert'
            />
          </div>
        </div>
        <div className='hero__image-container'>
          <Image
            src='/hero_photo.jpg'
            alt='hero image'
            width={685}
            height={518}
            objectFit='cover'
            className='hero__image'
            priority
          />
        </div>
      </div>
    </div>
  );
};

export default Hero;
