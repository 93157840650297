import Image from 'next/image';

interface Props {
  allClients: number;
}

const LogoSlider = (props: Props) => {
  const { allClients } = props;

  return (
    <div className='logo-slider-wrapper'>
      <header className='logo-slider__header'>
        <p className='logo-slider__header-text'>
          Trusted by <span>over {allClients} companies</span>, including:
        </p>
      </header>
      <div className='logo-slider'>
        <div className='logo-slider__slider-track slider-track'>
          <div className='slider-track__slide'>
            <Image
              src={'/customer-logos.svg'}
              width={2570}
              height={51}
              alt={'customer logos'}
              objectFit='cover'
              loading='eager'
              className='logo-slider__slider-logo'
            />
            <Image
              src={'/customer-logos.svg'}
              width={2570}
              height={51}
              alt={'customer logos'}
              objectFit='cover'
              loading='eager'
              className='logo-slider__slider-logo'
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default LogoSlider;
